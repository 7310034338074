import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthDataV2CSModel } from './auth-data-v2-cs.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LoginResponse } from '../datamodels/loginResponse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AirAuthModel } from '../datamodels/masterModel';
import { SettingsService } from '../services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthV2CsServiceService {
  private mtoken: string;
  private isAirAuthenticated = false;
  private mtokenTimer: any;
  private airLoginUserInfo: any
  private airAuthStatusListener = new Subject<boolean>(); //use this subject to push auth information into components that are interested and listening
  mexpirationDate: Date;
  Checkthis = [];
  private domain: string | undefined


  constructor(private http: HttpClient, private router: Router,
    private _snackBar: MatSnackBar, private settingsService: SettingsService
  ) {
    this.domain = settingsService.baseURL
  }
  createAirUser(
    firstname: string,
    lastname: string,
    //account contact details
    email: string,
    phone: string,
    password: string,
  ) {
    const authregisterMasterData: AirAuthModel =
    {
      _id: null,
      accounttype: null,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      password: password,
    }
    return this.http.post(`${this.domain}api/master/signup`, authregisterMasterData)
  }

  loginAirUser(email: string, password: string, masterKey: string) {
    const authloginData: any = {
      email: email, password: password, masterKey: masterKey
    }
    //post<{token:string}> makes the api aware that a token is available in response (we configured the api so we know)
    this.http.post<{
      token: string, expiresIn: number, userId: string
      , firstname: string, lastname: string, email: string, phone: string, accounttype: string
    }>(`${this.domain}api/master/login`,
      authloginData).subscribe(response => {
        const mtoken = response.token
        this.mtoken = mtoken
        if (mtoken) {
          //set expire time for authentication
          const expiresInDuration = response.expiresIn
          this.setAuthTimer(expiresInDuration);
          this.isAirAuthenticated = true
          //after successful login userinfo is extracted
          this.airLoginUserInfo = {
            userId: response.userId,
            firstname: response.firstname,
            lastname: response.lastname,
            // phone: response.phone,
            email: response.email,
            phone: response.phone,
            mexpiration: response.expiresIn
          }
          this.airAuthStatusListener.next(true); //true value is now emitted asObservable and any component interested can listen to it and verify user login status
          //to save mexpiration date we first need current timestamp
          const now = new Date();
          //mexpiration date is set by giving a date and adding 1 hour of login time as mexpiration time
          const mexpirationDateStruc = new Date(now.getTime() + expiresInDuration * 1000);
          const currentUserId = response.userId
          this.saveAirAuthData(mtoken, mexpirationDateStruc, this.airLoginUserInfo)
          this.router.navigate(['/']);
        }
      }, error => {
        this.loginError(error.error.message, "Retry")
        this.airAuthStatusListener.next(false);
      })
  }


  // login error notific
  loginError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }

  // Clear items on logout
  logoutAirUser() {
    //remove the token
    this.mtoken = null;
    //change auth status
    this.isAirAuthenticated = false;
    //authstatus ready to be passed into components
    this.airAuthStatusListener.next(false);
    //clear timer
    clearTimeout(this.mtokenTimer);
    //set loginuserinfo to null when logout is successful
    this.airLoginUserInfo = null;
    // this.airLoginUserInfo = null;
    //clear auth data from local storage
    this.clearAirAuthData();
    //logout to a route
    this.router.navigate(['/'])
  }

  async getAirAccountDetails(userId: string): Promise<any> {
    const maccountInfo = await this.http.get<any>(`${this.domain}api/master/getMasterInfo/` + userId).toPromise()
    return maccountInfo
  }

  getAirLoginInfo() {
    //used when login()
    return this.airLoginUserInfo
  }

  getIsAirAuth() {
    return this.isAirAuthenticated;
  }
  getAirToken() {
    //since token is private - we need to call this to access this token variable
    return this.mtoken
  }
  getairAuthStatusListener() {
    //need not extract token again and again, once extracted the boolean variable of subject will determine either the user is logged in or not
    return this.airAuthStatusListener.asObservable(); //need not emit values from other components, just required to emit form this service so used asObservable
  }
  autoAuthAirUser() {
    const authInformationofLocalStorage = this.getAirAuthData();
    // if no local storage data :
    if (!authInformationofLocalStorage) {
      return;
    }
    const now = new Date();
    const authSessionDifference = authInformationofLocalStorage.mexpirationDate.getTime() - now.getTime();
    if (authInformationofLocalStorage.mtoken && authSessionDifference > 0) {
      //means user is still authenticated , now we also need to set timer
      this.mtoken = authInformationofLocalStorage.mtoken;
      this.isAirAuthenticated = true;
      //if token is valid, initialize LoginUserObject with authinformationlocalstorage LogingUserObject
      //this part is dicey - should be usong this.airLoginUserInfo instead of this.storedLoginInfo
      this.airLoginUserInfo = authInformationofLocalStorage.airLoginUserInfo
      //since authtimer works with Seconds and authsessionDifference is coming in Miliseconds, we divide by 1000
      this.setAuthTimer(authSessionDifference / 1000)
      this.airAuthStatusListener.next(true);
    }
    if (!authInformationofLocalStorage.mtoken) {
      this.isAirAuthenticated = false;
      //since authtimer works with Seconds and authsessionDifference is coming in Miliseconds, we divide by 1000
      this.setAuthTimer(authSessionDifference / 1000)
      this.airAuthStatusListener.next(true);
    }
  }
  private setAuthTimer(duration: number) {
    //setTimeout gives nodejs timer but it gives in ms so we multiple it by 1000 to get seconds : 3600 * 1000 is 1 hour
    this.mtokenTimer = setTimeout(() => {
      this.logoutAirUser();
    }, duration * 1000)
  }
  //save token and mexpiration date in local storage
  private saveAirAuthData(mtoken: string, mexpirationDate: Date, airLoginUserInfo: LoginResponse) {
    localStorage.setItem('mtoken', mtoken);
    localStorage.setItem('mexpiration', mexpirationDate.toISOString());
    //since we are saving login token and date in local storage for autoauth- we also must save Userinfo for autoauth
    localStorage.setItem('airLoginUserInfo', JSON.stringify(airLoginUserInfo));

  }
  //clear token and mexpiration date and loginuserinfo from local storage after TTL session expires (time to live)
  private clearAirAuthData() {
    localStorage.removeItem('mtoken');
    localStorage.removeItem('mexpiration');
    localStorage.removeItem('airLoginUserInfo');
  }

  private getAirAuthData() {
    try {
      //instead of token or mexpirationDate use the name what is being saved in localstorage while login using method LocalStorage.setitem('name',value)
      const mtoken = localStorage.getItem("mtoken");
      const mexpirationDate = localStorage.getItem("mexpiration")
      //authenticated user - after signup and login info
      const rawLoginInfo = localStorage.getItem("airLoginUserInfo")
      const airLoginUserInfo = JSON.parse(rawLoginInfo)
      //cart items for the user 
      // Initialize shopping cart with default value
      return {
        mtoken: mtoken,
        //take date from local storage and get serialized date using new Date constructor to display
        mexpirationDate: new Date(mexpirationDate),
        //get loginuserinfo for autoauthuser
        airLoginUserInfo: airLoginUserInfo,
      }
    }
    catch (e) { }

  }


  // forgotPassword(email: string) {
  //   const userEmailCheck: any = {
  //     email: email
  //   }
  //   //post<{token:string}> makes the api aware that a token is available in response (we configured the api so we know)
  //   return this.http.post<{
  //   }>('http://localhost:3100/api/user/forgotPassword',
  //     userEmailCheck)
  // }


  // resetPasswordwithOTP(userId: string, resetpasswordOTP: number, password: string): Promise<any> {
  //   console.log('email', userId, 'otp', resetpasswordOTP)
  //   const resetPassword: any = {
  //     password: password
  //   }

  //   return this.http.put<any>(`http://localhost:3100/api/user/resetPassword/${userId}/${resetpasswordOTP}`, resetPassword).toPromise()
  // }

  // updateUserDetails(
  //   userId: string,
  //   accounttype: string,
  //   accountSubtype: string,
  //   firstname: string,
  //   lastname: string,
  //   //account contact details
  //   // email: string,
  //   phone: string,
  //   // password: string,
  //   isRegistered: boolean,
  //   //COMPANY DETAILS
  //   companyName: string,
  //   pinCode: number,
  //   state: string,
  //   city: string,
  //   addressLocality: string,
  //   addressBuildingNameAndFloor: string,
  //   landmark: string,

  //   //account ID details
  //   //license or MCARegistered 
  //   // incorporationType: string;
  //   // licenseId: string;
  //   isGSTAvailable: boolean,
  //   GST_Id: string,
  //   isGSTVerified: boolean,
  //   isPANAvailable: boolean,
  //   PAN_Id: string,
  //   isPANVerified: boolean,

  // ): Observable<any> {
  //   const updateUserData = new FormData();
  //   updateUserData.append("accounttype", accounttype);
  //   updateUserData.append("accountSubtype", accountSubtype);
  //   updateUserData.append("firstname", firstname);
  //   updateUserData.append("lastname", lastname);
  //   updateUserData.append("phone", phone);
  //   updateUserData.append("isRegistered", isRegistered.toString());
  //   updateUserData.append("companyName", companyName);
  //   updateUserData.append("pinCode", pinCode.toString());
  //   updateUserData.append("state", state);
  //   updateUserData.append("city", city);
  //   updateUserData.append("addressLocality", addressLocality);
  //   updateUserData.append("addressBuildingNameAndFloor", addressBuildingNameAndFloor);
  //   updateUserData.append("landmark", landmark);
  //   updateUserData.append("isGSTAvailable", isGSTAvailable.toString());
  //   updateUserData.append("GST_Id", GST_Id);
  //   updateUserData.append("isGSTVerified", isGSTVerified.toString());
  //   updateUserData.append("isPANAvailable", isPANAvailable.toString());
  //   updateUserData.append("PAN_Id", PAN_Id);
  //   updateUserData.append("isPANVerified", isPANVerified.toString());
  //   console.log('reache')

  //   return this.http.put<{ message: string, status: string }>('http://localhost:3100/api/user/updateInformation/' + userId, updateUserData)

  // }



}
