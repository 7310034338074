<div class="toolBar">
  <span *ngIf="!hideBackButton" class="toolButton back"
    (click)="goback()"><mat-icon>keyboard_arrow_left</mat-icon></span>
  <span *ngIf="!hideBackButton" routerLink="/" class="toolButton home"><mat-icon>home</mat-icon></span>
  <span routerLink="/MyBookings" class="toolButton tagline">
    Bookings
  </span>

  <span routerLink="/FAQs" class="toolButton tagline">
    FAQs
  </span>

  <span *ngIf="!this.userIsAuthenticated" routerLink="/Login" class="toolButton tagline">
    Login
  </span>
  <span *ngIf="this.userIsAuthenticated" (click)="this.LogoutUser()" class="toolButton tagline">
    Logout
  </span>
  <span routerLink="/Register" class="toolButton tagline">
    Register
  </span>
  <a class="toolButton mypaar" target="_blank" href="https://www.myapaar.com"> <img class="myapaarIcon"
      src="./../assets/welcomebanner/mainHome.png" /></a>
</div>
<router-outlet></router-outlet>
<div class="useStack footer">
  <app-footer-page></app-footer-page>
</div>