import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-book-consultation',
  templateUrl: './book-consultation.component.html',
  styleUrls: ['./book-consultation.component.css']
})
export class BookConsultationComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  fillServiceDetails(serviceType: string) {
    if (serviceType === undefined || !serviceType) {
      return
    }
    this.router.navigate(['AddConsultation', serviceType])
  }
  openLink() {
    window.open('https://www.myapaar.com', '_blank')
  }
}
