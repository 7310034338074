import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcomelogo',
  templateUrl: './welcomelogo.component.html',
  styleUrls: ['./welcomelogo.component.css']
})
export class WelcomelogoComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit(): void {

  }

}
