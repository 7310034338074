import { Injectable } from '@angular/core';
import { PostsAndFaqFullInfoModel } from '../datamodels/PostsFaqs/faqAndPostsModel';
import { Meta } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class SEOairService {
  useFaq: PostsAndFaqFullInfoModel[] = [
    {
      id: 1,
      question: 'What is All Indus Roots ?',
      answer: 'Founded in 2021, All Indus Roots stands as a beacon of expertise in the agricultural domain, offering unparalleled consultation services to individual farmers, plantation companies, and various organizations involved in agriculture produce and trade. With a vision to empower the agricultural sector, our company has been dedicated to providing strategic guidance and innovative solutions to address the unique challenges faced by our clients.',
      p1: 'All Indus Roots is not just a consultancy but also your partner on the journey towards sustainable and efficient agricultural practices. Our team of experts brings a wealth of knowledge and experience, ensuring that clients receive tailored advice on crop management, pest control, soil health, irrigation practices, market strategies, and more. ',
      p2: 'As a company, we take pride in our commitment to inclusivity and accessibility. Our online consultation services break down geographical barriers, reaching farmers and organizations globally. Whether a small-scale farmer seeking advice or a large coffee plantation company looking to optimize operations, All Indus Roots offers a range of consultation packages to suit diverse needs.',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 2,
      question: 'What services does All Indus Roots provide ?',
      answer: 'Our team of experts provides guidance on diverse topics, including crop management, sustainable practices, and market trends.',
      p1: 'All Indus Roots offers consultation services to individual farmers, plantation companies, and organizations involved in agriculture produce and trade. Our expertise covers a wide range of agricultural practices, providing valuable insights and solutions.',
      p2: 'Myapaar is our e-commerce platform where 4 crore Indian merchants find power as online traders. Myapaar establishes bulk trade and product exchange, simplifying buying and selling in large quantities. Managing inventory effortlessly to streamline business operations. Buyers benefit from maximum savings by purchasing in bulk, while sellers can boost their sales by connecting with millions of buyers on platform. Join us Myapaar for an easy and established marketplace experience, where business growth meets convenience.',
      list: ['Precision Agriculture', 'Hydroponic Farming', 'Soil Testing & Analysis', 'Crop Planning & Rotation', 'Water Management & Irrigation', 'Agriculture Training Sessions', 'Crop Genetics & Breeding', 'Crop Disease & Pest Control', 'Scientist Site Visit', 'Bulk Selling and Buying',],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 3,
      question: 'What packages does All Indus Roots offer for consultation ?',
      answer: 'We provide three consultation packages: the Free Package with a 15-minute audio session, the Single Package with a 1-hour video session available at a fee, and the Project Package, a paid option with 26 hours of dedicated video sessions for addressing complex agricultural challenges.',
      p1: 'Free Package - Offers a brief yet insightful 15-minute audio session',
      p2: 'Paid Packages - Single Package provides a comprehensive 1-hour video session for those seeking more in-depth guidance. The Project Package extends to 26 hours of video sessions, designed for tackling intricate challenges in agricultural practices',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 4,
      question: 'How does the Free Package work ?',
      answer: 'The Free Package is a great starting point for clients seeking quick advice. With a 15-minute audio session, individuals can engage with our experts, briefly outlining their concerns, and gain valuable preliminary insights—all without incurring any charges.',
      p1: 'Signup and Login',
      p2: 'Book a free consultation. Done ! Our team will reach out at the booking time to address your problems and help you with an expert solution.',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 5,
      question: 'What is included in the Single Package ?',
      answer: 'The Single Package is designed for clients looking for a more extensive consultation experience. It includes a 1-hour video session with our experts, allowing for a deeper discussion on specific agricultural challenges. This package comes at a reasonable fee to ensure accessibility.',
      p1: 'Signup and Login  >  Book a Single Consultaion',
      p2: 'You can join the video session and pay us right before the meeting starts after our Host and Expert Scientist joins to help you. QR code or Payment link will be provided when the meeting begins.',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 6,
      question: 'How can clients benefit from the Project Package ?',
      answer: 'Clients with complex agricultural issues can maximize their benefits with the Project Package. This comprehensive offering provides 26 hours of video sessions, allowing for an in-depth exploration and resolution of intricate challenges in agricultural practices.',
      p1: 'Signup and Login  >  Book a Project Consultaion',
      p2: 'You can join the video session and pay us right before the meeting starts after our Host and Expert Scientist joins to help you. QR code or Payment link will be provided when the meeting begins.',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 7,
      question: 'How can I schedule a consultation session ?',
      answer: 'To schedule a consultation session, simply visit our website at https://www.allindusroots.in and navigate to the Categories provided on Homepage. Choose your preferred package - Free ; Single ; Project , select a convenient time slot by clicking on Book, and follow the prompts to confirm your session.',
      p1: '',
      p2: '',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 8,
      question: 'Can I change or reschedule my consultation appointment ?',
      answer: `Yes, you can modify or reschedule your consultation appointment. Simply log in to your account on our website, go to the "Bookings" section, and follow the provided options for making adjustments.`,
      p1: '',
      p2: '',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 9,
      question: 'Is there a refund policy for consultation services',
      answer: 'Refunds are not available for the Free Package. However, for the Single and Project Packages, refunds are considered on a case-by-case basis. Please review our refund policy on the website for detailed information',
      p1: '',
      p2: '',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 10,
      question: 'Are the consultation sessions recorded',
      answer: 'Yes, all video consultation sessions are recorded for quality assurance and reference purposes. However, client confidentiality is of utmost importance, and recordings are strictly used for internal purposes.',
      p1: '',
      p2: '',
      list: [],
      metaTag: '',
      imgLink: ''
    },
    {
      id: 11,
      question: 'What types of agricultural challenges can be addressed during consultation sessions ?',
      answer: 'All Indus Roots specializes in addressing various agricultural challenges for all crops. Our expert team is well-equipped to provide guidance on crop management, pest control, soil health, irrigation practices, and market strategies, ensuring a comprehensive approach to agricultural problem-solving.',
      p1: '',
      p2: '',
      list: [],
      metaTag: '',
      imgLink: ''
    },

    {
      id: 12,
      question: 'Is All Indus Roots limited to specific geographical regions for consultation services ?',
      answer: 'All Indus Roots breaks geographical barriers with our online consultation services. We cater to clients globally, offering expertise and guidance to farmers and organizations around the world. Our commitment to accessibility ensures that geographical location is not a limitation for our services.',
      p1: '',
      p2: '',
      list: [],
      metaTag: '',
      imgLink: ''
    },

    {
      id: 13,
      question: 'Is there ongoing support after the consultation sessions ?',
      answer: 'Yes, All Indus Roots provides ongoing support through various channels, including email and online chat. Clients can reach out with follow-up questions or concerns, ensuring continuous assistance beyond the initial consultation sessions.',
      p1: 'Our commitment to client satisfaction extends beyond the consultation',
      p2: '',
      list: [],
      metaTag: '',
      imgLink: ''
    },
  ]
  useTheseQuestions: PostsAndFaqFullInfoModel[] = []

  constructor(private meta: Meta) { }

  getFAQsSetMeta() {
    this.useTheseQuestions = this.getFAQsOnly()
    this.addMetaTags(this.useTheseQuestions)
    return this.useTheseQuestions
  }

  getFAQsOnly() {
    this.useTheseQuestions = this.useFaq.map((eachQuestion: PostsAndFaqFullInfoModel) => {
      const metaTerm = eachQuestion.question.substring(0, (eachQuestion.question + "?").indexOf("?")).replace(/ /g, "_");
      const useMetaInfo = { metaTag: metaTerm }
      const newQuestionMetaInfo = { ...eachQuestion, ...useMetaInfo }
      return newQuestionMetaInfo
    })
    return this.useTheseQuestions
  }

  addMetaTags(faqs: PostsAndFaqFullInfoModel[]) {
    let useMetaInfo = faqs.map(eachFaq => {
      const metaInfo = {
        name: eachFaq.question,
        content: eachFaq.answer,
        property: ''
      }
      return metaInfo
    })
    const descriptiveSite = [
      { name: 'keywords', property: "og:keywords", content: 'All Indus Roots, AIR, A.I.R, allindusroots, All Indus Roots Facebook, All Indus Roots instagram, All Indus Roots website, All Indus Roots Consultation, Crop Consultation, Agriculture Consultation, Crop Company Consultation, Seed Breeding Consultation, Crop Scientists India, Crop Research, Field Research, Scientist Essential Oils, CSIR, C.S.I.R, CMAP, C.M.A.P, Precision Agriculture, Precision Agriculture Consultation, Soil Testing, Soil Analysis, Crop Plantation, Crop Rotation, Water Management Consultation, Irrigation, Agriculture Training Sessions, Crop Genetics, Crop breeding, Crop disease control, Crop pest control, Crop Research Scientist, Crop Scientist Visit.' },
      { name: 'copyright', property: "og:copyright", content: 'All Indus Roots - Growing With India' },
      { name: 'application-name', property: "og:type", content: 'All Indus Roots' },
      { name: 'description', property: "og:description", content: 'All Indus Roots is a consultancy company and also your partner on the journey towards sustainable and efficient agricultural practices. Our team of expert scientists with prestigious experience from Indian Government Research Foundations such as C.S.I.R, C.M.A.P, I.C.A.R and many else, bring a wealth of knowledge and experience, ensuring that clients receive tailored advice on crop management, pest control, soil health, irrigation practices, market strategies, and more.' },
      { name: 'generator', property: "og:app", content: 'Angular' },
      { name: 'website', property: "og:url", content: 'https://www.allindusroots.in' },
      { name: 'facebook', property: "fb:app_id", content: '61553258164931' },
      { name: 'facebook', property: "fb:site", content: 'https://www.facebook.com/Allindusroots' },
      { name: 'facebook', property: "fb:description", content: 'All Indus Roots, AIR Facebook, Indus Roots Facebook, All Indus Roots website, E-Consultation, Scientist Consultation Facebook All Indus roots' },
      { name: 'facebook', property: "instagram:app_id", content: 'allindusroots' },
      { name: 'facebook', property: "instagram:site", content: 'https://www.instagram.com/allindusroots/' },
      { name: 'facebook', property: "instagram:description", content: 'All Indus Roots Instagram, AIR IG, Indus Roots Insta, Scientist Research Meeting All Indus roots' },
      { name: 'facebook', property: "linkedin:site", content: 'https://www.linkedin.com/in/all-indus-roots' },
      { name: 'facebook', property: "linkedin:app_id", content: 'allindusroots' },
      { name: 'facebook', property: "linkedin:description", content: 'All Indus Roots Linkedin, AIR in, Indus Roots Linkedin' },
    ]
    useMetaInfo = [...useMetaInfo, ...descriptiveSite]
    this.meta.addTags(useMetaInfo)
  }
}
