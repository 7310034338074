import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthV2CsServiceService } from './auth-v2-cs-service.service';


@Injectable()
export class AuthGuardV2CS implements CanActivate {
    constructor(private authV2Service: AuthV2CsServiceService, private router: Router) { }
    canActivate(route: import("@angular/router").ActivatedRouteSnapshot,
        state: import("@angular/router").RouterStateSnapshot): boolean
        | import("@angular/router").UrlTree
        | import("rxjs").Observable<boolean
            | import("@angular/router").UrlTree> | Promise<boolean
                | import("@angular/router").UrlTree> {
        //getisauth gets current loggedin or loggedout information as boolean from authservice
        const isAuth = this.authV2Service.getIsAirAuth();
        if (!isAuth) {
            //if not authenticated
            this.router.navigate(['/Login'])
        }
        return isAuth
        //router lets access when return true, router denies access to route if returned false
    }
}