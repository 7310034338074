<div class="contentFAQ">
    <div class="faq-header">Frequently Asked Questions</div>
    <div class="faq-content">
        <div *ngFor="let eachFAQ of useFaq">
            <div (click)="goToPosts(eachFAQ.id,eachFAQ.metaTag)" class="thisFAQ">
                <span class="useQuestion">
                    {{eachFAQ.question}}
                </span>
                <span class="icon">
                    <mat-icon>open_in_new</mat-icon>
                </span>
            </div>
        </div>
    </div>
</div>