<div class="imageContainer">
    <div class="contentFAQ">
        <h1 class="faq-header">Frequently Asked Questions</h1>
        <div class="faq-content">
            <div class="thisFAQ">
                <h3 class="useQuestion">
                    {{this.usePost.question}}
                </h3>
            </div>
            <div class="thisPost">
                <h4 class="thisPostText" *ngIf="this.usePost.answer !== ''">{{this.usePost.answer}}</h4>
                <h3 class="thisPostText" *ngIf="this.usePost.p1 !== ''">{{this.usePost.p1}}</h3>
                <h3 class="thisPostText" *ngIf="this.usePost.p2 !== ''">{{this.usePost.p2}}</h3>
                <div *ngIf="this.usePost.list && this.usePost.list.length !== 0">
                    <div *ngFor="let listItem of this.usePost.list" class="listItem">
                        <h4 class="thisPostList">
                            {{listItem}}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>