import { Routes } from '@angular/router'
import { AppComponent } from 'src/app/app.component'
import { AuthGuardV2CS } from 'src/app/auth-v2-cs/auth.guard-v2-cs'
import { AboutComponent } from 'src/app/components/about/about.component'
import { BookingPageComponent } from 'src/app/components/booking-page/booking-page.component'
import { ContactComponent } from 'src/app/components/contact/contact.component'
import { FaqPostsComponent } from 'src/app/components/faq-posts/faq-posts.component'
import { LoginComponent } from 'src/app/components/login/login.component'
import { MyBookingsComponent } from 'src/app/components/my-bookings/my-bookings.component'
import { NavigationComponent } from 'src/app/components/navigation/navigation.component'
import { PartnerComponent } from 'src/app/components/partner/partner.component'
import { SignupAirComponent } from 'src/app/components/signup-air/signup-air.component'
import { ViewPostComponent } from 'src/app/components/view-post/view-post.component'

export const newroutes: Routes = [
    { path: '', component: NavigationComponent, pathMatch: 'full' },
    { path: 'FAQs', component: FaqPostsComponent },
    { path: 'Posts/:id/:metaInfo', component: ViewPostComponent },
    { path: 'AboutUs', component: AboutComponent },
    { path: 'PartnerWithUs', component: ContactComponent },
    { path: 'ContactUs', component: PartnerComponent },
    { path: 'AddConsultation/:consultationType', component: BookingPageComponent, canActivate: [AuthGuardV2CS] },
    { path: 'Login', component: LoginComponent },
    { path: 'MyBookings', component: MyBookingsComponent, canActivate: [AuthGuardV2CS] },
    { path: 'Register', component: SignupAirComponent },
] 