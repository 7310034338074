<body>
    <footer class="footer">
        <div class="waves">
            <div class="wave" id="wave1"></div>
            <div class="wave" id="wave2"></div>
            <div class="wave" id="wave3"></div>
            <div class="wave" id="wave4"></div>
        </div>
        <ul class="menu">
            <li class="menu__item"><a mat-stroked-button class="menu__link" target="_blank"
                    href="https://www.myapaar.com">Myapaar</a></li>
            <li class="menu__item"><a mat-stroked-button routerLink="/AboutUs" class="menu__link">About</a></li>
            <li class="menu__item"><a mat-stroked-button class="menu__link" target="_blank" rel="noopener"
                    href="mailto:solutions@allindusroots.com">Contact</a></li>
            <!-- <li class="menu__item"><a mat-stroked-button routerLink="/ContactUs" class="menu__link">Partner</a></li> -->
        </ul>
        <p>&copy;2021 All Indus Roots | All Rights Reserved</p>
    </footer>
    <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
    <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
</body>