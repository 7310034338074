<div class="contentBookings">
    <div *ngIf="this.viewMyBookings && this.viewMyBookings.length > 0" class="allBookings">
        <div class="eachBooking">

            <div class="serviceBanner">
                <img src="../../../assets/welcomebanner/myBookings.jpg" alt="all indus roots bookings"
                    style="width:100%;">
                <div class="gradient"></div>
                <div class="centered">My Bookings</div>
            </div>
            <div class="rulesToBookMeetings">
                <mat-card>
                    <div class="header">
                        <p class="ruleHeader">Booking Rules</p>
                    </div>
                    <div class="rules">
                        <p> <mat-hint>Make one booking at a time.</mat-hint></p>
                        <p> <mat-hint>Please do not overbook your slot or other's slots.</mat-hint></p>
                        <p> <mat-hint>If you make double bookings, oldest one will be cancelled.</mat-hint> </p>
                    </div>
                </mat-card>
            </div>
            <div *ngIf="isLoading">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="!isLoading">
                <div *ngFor="let eachBooking of this.viewMyBookings" class="bookingCard">
                    <mat-card>
                        <div class="eachCard">
                            <div class="titleDate">
                                <div class="head">
                                    <div class="title">
                                        <h1 class="titleFont">
                                            {{eachBooking.serviceName}}
                                        </h1>
                                    </div>
                                </div>
                                <div class="date-number">
                                    <h1 class="number">
                                        {{eachBooking.bookingNumber}}
                                    </h1>
                                    <h1 class="date">
                                        Ordered on {{eachBooking.bookingDate | date}}
                                    </h1>
                                </div>
                            </div>
                            <div class="typeHoursPrice">
                                <div class="consult freeType" *ngIf="eachBooking.isFree=='true'">
                                    <div class="type">
                                        Free
                                    </div>
                                    <div class="price">
                                        No Charge
                                    </div>
                                    <div class="hours">
                                        15 Minutes
                                    </div>
                                </div>
                                <div class="consult singType" *ngIf="eachBooking.isSingle=='true'">
                                    <div class="type">
                                        Single
                                    </div>
                                    <div class="price">
                                        {{(eachBooking.consultationPrice).toLocaleString('en-In')}} ₹
                                    </div>
                                    <div class="hours">
                                        {{eachBooking.consultationHours}} Hour
                                    </div>
                                </div>
                                <div class="consult projType" *ngIf="eachBooking.isProject=='true'">
                                    <div class="type">
                                        Project
                                    </div>
                                    <div class="price">
                                        {{(eachBooking.projectPrice).toLocaleString('en-In')}} ₹
                                    </div>
                                    <div class="hours">
                                        {{eachBooking.projectHours}} Hours
                                    </div>
                                </div>
                            </div>
                        </div>


                        <mat-action-row>
                            <div class="meetingAction">
                                <div class="meetingButton">
                                    <div class="linkMeet">
                                        <a mat-raised-button class="createMeeting" [href]="eachBooking.bookMeetingLink"
                                            target="_blank">Book
                                            Meeting</a>
                                    </div>
                                    <div class="cancelBooking">
                                        <a mat-raised-button class="cancelButton"
                                            (click)="cancelConsultation(eachBooking.bookingId)">Cancel</a>
                                    </div>
                                </div>
                                <div class="note">
                                    <div *ngIf="eachBooking.paymentRequired===true">
                                        <span *ngIf="eachBooking.paymentDone===true">
                                            Paid
                                        </span>
                                        <span *ngIf="eachBooking.paymentDone===false">
                                            Pay after meeting half-time
                                        </span>
                                    </div>
                                    <span *ngIf="eachBooking.paymentRequired===false">
                                        Ask anything related to your Crop problems for free
                                    </span>
                                </div>

                            </div>
                        </mat-action-row>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!this.viewMyBookings ||  this.viewMyBookings && this.viewMyBookings.length == 0" class="noBookings">
        <mat-card>
            <p class="None title">
                No bookings found
            </p>
        </mat-card>
    </div>
</div>