import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthV2CsServiceService } from 'src/app/auth-v2-cs/auth-v2-cs-service.service';

@Component({
  selector: 'app-signup-air',
  templateUrl: './signup-air.component.html',
  styleUrls: ['./signup-air.component.css']
})
export class SignupAirComponent implements OnInit {
  isLoading: boolean = false;
  masterSignupform: FormGroup
  private authListenerSubs: Subscription;
  hide = true;
  selectedPincode: string = '';
  // validationMessages: Validations
  // formErrors: Validations
  // accountTypes: AccountType[] = [
  //   { id: 1, accountTypeName: "Seller", accountSubType: ["Producer", "Distributor", "Wholesaler"] },
  //   { id: 2, accountTypeName: "Buyer", accountSubType: ["Wholesaler", "Distributor", "Retailer"] }]
  validationMessages = {
    // 'accounttype': {
    //   'required': 'Account type is required'
    // },
    'firstname': {
      'required': 'First name is required',
      'maxlength': 'First name cannot have more than 50 characters!'
    },
    'lastname': {
      'required': 'Last name is required',
      'maxlength': 'Last name cannot have more than 50 characters!'
    },
    //
    'password': {
      'required': 'Password is required'
    },
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
    'email': {
      'required': 'Please Add Email. ',
      'pattern': 'Email not in valid format'
    },
  }
  //js object to display form errors
  formErrors = {
    'firstname': '',
    'lastname': '',
    'password': '',
    'phone': '',
    'email': '',
  }
  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    public authV2CSService: AuthV2CsServiceService
  ) { }

  ngOnInit(): void {
    this.authListenerSubs = this.authV2CSService.getairAuthStatusListener().subscribe(
      //we get auth status which is a boolean
      authStatus => {
        this.isLoading = false;
      }
    )
    this.masterSignupform = new FormGroup({
      firstname: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
      lastname: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
      password: new FormControl('', { validators: [Validators.required] }),
      phone: new FormControl('', {
        validators: [Validators.minLength(10), Validators.maxLength(10)]
      }),
      email: new FormControl('', {
        validators: [Validators.maxLength(250), Validators.minLength(5), Validators.pattern(/.+@.+\..+/)]
      }),
    });
    this.masterSignupform.valueChanges.subscribe(data => {
      this.onValueChanged(data)
    });
    this.onValueChanged();
  }

   
  onValueChanged(data?: any) {
    if (!this.masterSignupform) {
      return;
    }
    const sform = this.masterSignupform;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        //clear previous messages if any
        this.formErrors[field] = '';
        const control = sform.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              if (messages[key] === undefined) {
                messages[key] = ''
              }
              this.formErrors[field] += messages[key] + '';
            }
          }
        }
      }
    }
  }

  ngOnDestroy() {
    //prevent any data leak
    this.authListenerSubs.unsubscribe();
  }

  onSignup() {
    try {
      if (this.masterSignupform.invalid) {
        return
      }
      if (this.masterSignupform.valid) {
        this.isLoading = true; //spinner starts

        this.authV2CSService.createAirUser(
          this.masterSignupform.value.firstname,
          this.masterSignupform.value.lastname,
          this.masterSignupform.value.email,
          this.masterSignupform.value.phone,
          this.masterSignupform.value.password,
        ).subscribe(response => {
          this.signupSuccessful(`Registration Successful ! Email verification link sent to ${this.masterSignupform.value.email} !`, "Okay")
          this.isLoading = false; //spinner starts
          this.router.navigate(["/"]);
        },
          //second argument of subscribe
          error => {
            this.isLoading = false; //spinner starts
            // this.authStatusListener.next(false);
            this.signupError(`${this.masterSignupform.value.email} is already registered !`, "Retry")
          });
      }
    }

    catch (e) {
    }

  }
  signupSuccessful(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
}
