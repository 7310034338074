<div class="signUp">
    <mat-card>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <form [formGroup]="masterSignupform" (submit)="onSignup()" *ngIf="!isLoading">
            <mat-form-field appearance="outline">
                <input matInput type="text" name="firstname" formControlName="firstname" placeholder="First Name"
                    required>
                <mat-error *ngIf="formErrors.firstname">{{formErrors.firstname}}</mat-error>
                <mat-hint>Jack</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput type="text" name="lastname" formControlName="lastname" placeholder="Last Name">
                <mat-error *ngIf="formErrors.lastname">{{formErrors.lastname}}</mat-error>
                <mat-hint>Smith</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput name="email" formControlName="email" placeholder="Email"
                    required>
                <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                <mat-hint>MahendraXXXXtap@gmail.com</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput type="tel" name="phone" formControlName="phone" placeholder="Phone Number"
                    pattern="[0-9]*" maxlength="10" required>
                <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                <mat-hint>98xxxxxxx9</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput type="password" name="password" formControlName="password" placeholder="Password"
                    [type]="hide? 'password':'text'" required>
                <button mat-icon-button matSuffix (click)="hide=!hide" type="button" [attr.aria-label]="'Hide Password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide? 'visibility_off' : 'visibility'}}</mat-icon></button>
                <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
            </mat-form-field>
            <button class="submit-button" mat-raised-button color="accent" type="submit">Sign Up
            </button>
        </form>
    </mat-card>
</div>