import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { SavedConsultationsUser } from '../datamodels/userConsultationBookings';
import { ConsultationBooking } from '../datamodels/newConsultationModel';

@Injectable({
  providedIn: 'root'
})
export class BookServicesService {
  checkString: any
  private domain: string | undefined
  constructor(private http: HttpClient
    , private settingsService: SettingsService
  ) {
    this.domain = settingsService.baseURL
  }
  getPincodeDetails(pinCode: string): Promise<any> {
    return this.http.get<any>(`${this.domain}api/states/pincode/` + pinCode).toPromise()
    // `https://api.postalpincode.in/pincode/${pinCode}`
  }

  saveConsultationBooking(consultationUser: SavedConsultationsUser, userId: string): Promise<{ status: number, createdConsultation: SavedConsultationsUser, bookingId: string }> {
    const consultationDetails = new FormData()
    const queryparam = `?userId=${userId}`
    consultationDetails.append('userDetails', JSON.stringify(consultationUser.userDetails))
    consultationDetails.append('consultationDetails', JSON.stringify(consultationUser.consultationDetails))
    consultationDetails.append('paymentRequired', JSON.stringify(consultationUser.paymentRequired))
    consultationDetails.append('paymentDone', JSON.stringify(consultationUser.paymentDone))
    const saveConsultation = this.http.post<{ status: number, createdConsultation: SavedConsultationsUser, bookingId: string }>(`${this.domain}api/bookConsultation/saveConsultation/` + queryparam, consultationDetails).toPromise()
    return saveConsultation
  }
  payConsultationBooking(pinCode: string): Promise<any> {
    return this.http.get<any>(`${this.domain}api/states/pincode/` + pinCode).toPromise()
    // `https://api.postalpincode.in/pincode/${pinCode}`
  }
  async getUserBookings(userId: string): Promise<{ status: number; allBookings: any[]; message: string }> {
    const queryparam = `?userId=${userId}`
    const allbookings = await this.http.get<{ status: number; allBookings: any[]; message: string }>(`${this.domain}api/bookConsultation/getAllConsultations/` + queryparam).toPromise()
    return allbookings
  }

  async deleteBookingConsultation(bookingId: string): Promise<{ status: number, message: string }> {
    const queryparam = `?bookingId=${bookingId}`
    const deleteConfirm = await this.http.get<{ status: number; message: string }>(`${this.domain}api/bookConsultation/deleteConsultation/` + queryparam).toPromise()
    return deleteConfirm
  }
}
