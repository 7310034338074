import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthV2CsServiceService } from 'src/app/auth-v2-cs/auth-v2-cs-service.service';
import { BookServicesService } from 'src/app/services/book-services.service';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MyBookingsComponent implements OnInit {
  loginUserInfo: any;
  currentUserid: string;
  viewMyBookings: any;
  isLoading: boolean = true

  constructor(
    private bookServices: BookServicesService,
    private _snackBar: MatSnackBar,
    public authV2CSService: AuthV2CsServiceService,
    private router: Router) { }

  ngOnInit(): void {
    try {
      this.loginUserInfo = this.authV2CSService.getAirLoginInfo()
      this.currentUserid = this.loginUserInfo.userId
      this.loadAllBookings(this.currentUserid)
    }
    catch (e) {
      throw e
    }
  }
  loadAllBookings(userid: string) {
    try {
      this.bookServices.getUserBookings(userid).then((allBookings) => {
        this.viewMyBookings = allBookings.allBookings
        this.isLoading = false
      })
    }
    catch (e) {
      throw e
    }
  }

  cancelConsultation(bookingId: string) {
    try {
      this.bookServices.deleteBookingConsultation(bookingId).then((deleteSuccess) => {
        this.viewMyBookings = deleteSuccess.message
        this.isLoading = false
      })
    }
    catch (e) {
      throw e
    }
  }

  itemCreateSuccessful(message: string, action: string) {
    let snackBarRef = this._snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
    snackBarRef.onAction().subscribe(() =>
      this.loadAllBookings(this.currentUserid)
    )
    snackBarRef.afterDismissed().subscribe(() =>
      this.loadAllBookings(this.currentUserid)
    )
  }

}
