import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FAQModel } from '../../datamodels/PostsFaqs/faqModel';
import { MetaInfoModel } from '../../datamodels/PostsFaqs/metaInfoModel';
import { PostsAndFaqFullInfoModel } from '../../datamodels/PostsFaqs/faqAndPostsModel';
import { Title } from '@angular/platform-browser';
import { SEOairService } from 'src/app/services/seoair.service';
@Component({
  selector: 'app-faq-posts',
  templateUrl: './faq-posts.component.html',
  styleUrls: ['./faq-posts.component.css']
})
export class FaqPostsComponent implements OnInit {
  useFaq: PostsAndFaqFullInfoModel[] = []

  constructor(private router: Router, private airSEO: SEOairService, private title: Title) {
    this.useFaq = this.airSEO.getFAQsOnly()
  }

  ngOnInit(): void {
    this.title.setTitle('FAQs')
  }
  goToPosts(id: number, metaTag: string) {
    this.router.navigate(['/Posts', id, metaTag])
  }
}
