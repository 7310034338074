import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module';
import { APP_INITIALIZER, NgModule, inject } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppRoutingModule } from './../app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { BookConsultationComponent } from './components/book-consultation/book-consultation.component';
import { FooterPageComponent } from './components/footer-page/footer-page.component';
import { BookingPageComponent } from './components/booking-page/booking-page.component';
import { AboutComponent } from './components/about/about.component';
import { WelcomelogoComponent } from './components/welcomelogo/welcomelogo.component';
import { ContactComponent } from './components/contact/contact.component';
import { PartnerComponent } from './components/partner/partner.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { Authv2CSInterceptor } from './auth-v2-cs/auth-interceptor-v2-cs';
import { SettingsService } from './services/settings.service';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { LoginComponent } from './components/login/login.component';
import { SignupAirComponent } from './components/signup-air/signup-air.component';
import { MyBookingsComponent } from './components/my-bookings/my-bookings.component';
import { FaqPostsComponent } from './components/faq-posts/faq-posts.component';
import { ViewPostComponent } from './components/view-post/view-post.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BookConsultationComponent,
    FooterPageComponent,
    BookingPageComponent,
    AboutComponent,
    WelcomelogoComponent,
    ContactComponent,
    PartnerComponent,
    NavigationComponent,
    LoginComponent,
    SignupAirComponent,
    MyBookingsComponent,
    FaqPostsComponent,
    ViewPostComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: Authv2CSInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      const settingsServ = inject(SettingsService);
      const http = inject(HttpClient);
      return () => new Promise(resolve => {
        if (environment.production) {
          http.get('../config.json').subscribe((data: any) => {
            console.info('settingsServ.baseURL', settingsServ.baseURL)
            console.info('data.baseURL', data.baseURL)
            settingsServ.baseURL = data.baseURL;
            resolve(true);
          }), catchError(err => {
            console.info('err?')
            settingsServ.baseURL = 'https://allindusroots.myapaar.com/dharaa/';
            resolve(true);
            return null;
          })
        }
        else {
          //setings for local app
          const settings = require('../config-dev.json')
          console.info('localEnv')
          settingsServ.baseURL = settings.baseURL
          resolve(true);
        }
      });
    },
    multi: true,
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
