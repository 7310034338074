import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { AuthV2CsServiceService } from './auth-v2-cs/auth-v2-cs-service.service';
import { SEOairService } from './services/seoair.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  hideBackButton: boolean = false
  private authListenerSubs: Subscription
  title = 'air-fe';
  currentUserId: string
  loginInfo: any
  isUserAuthenticated: boolean;
  userIsAuthenticated: any;
  loginUserInfo: any;
  isGeneric: boolean;
  currentUserEmail: string;
  constructor(
    public authV2CSService: AuthV2CsServiceService,
    private location: Location, private router: Router,
    private useSEO: SEOairService) {
  }
  ngOnInit() {
    this.doAuth()
    this.useSEO.getFAQsSetMeta()
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        event.url && event.url === '/' ? this.hideBackButton = true : this.hideBackButton = false
      }
    });
  }
  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status
      this.authV2CSService.autoAuthAirUser();
      // this.authService.autoAuthUser();
      this.userIsAuthenticated = this.authV2CSService.getIsAirAuth();
      this.loginUserInfo = this.authV2CSService.getAirLoginInfo()
      if (this.loginUserInfo && this.loginUserInfo !== undefined) {
        this.currentUserId = this.loginUserInfo?.userId
        this.currentUserEmail = this.loginUserInfo?.email
      }
      if (this.userIsAuthenticated) {
        this.isGeneric = false
      }
      else if (!this.userIsAuthenticated) {
        this.isGeneric = true
      }
      this.authListenerSubs = this.authV2CSService.getairAuthStatusListener().subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        //if auth status changes, get user info again
        this.loginUserInfo = this.authV2CSService.getAirLoginInfo()
      });

    }
    catch (e) { }

  }
  goback() {
    this.location.back();
  }
  LogoutUser() {
    this.authV2CSService.logoutAirUser()
  }
}
