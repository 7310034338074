<div class="contentBooking">
    <div class="serviceCard">
        <div class="serviceBanner">
            <img [src]="this.currentBannerService" alt="Snow" style="width:100%;">
            <div class="gradient"></div>
            <div class="centered">{{this.currentServiceName}}</div>
        </div>
        <div class="bookingForm">
            <div class="head">
                <span class="title">Select Consultation Type</span> <br>
                <mat-hint class="hint">Grow like a Pro </mat-hint>
            </div>

            <mat-card>

                <div class="typeCard">
                    <div class="toggle">
                        <span class="freeText">Free</span>
                        <mat-slide-toggle class="togglePaid" [(ngModel)]="this.isPaid">
                            Paid
                        </mat-slide-toggle>
                    </div>
                    <div *ngIf="this.isPaid" class="selectOne">
                        <mat-tab-group #tabGroup (focusChange)="tabChanged($event)" [selectedIndex]="0">
                            <mat-tab label="Single">
                                <div class="singleCons consType">
                                    <p class="highlight">
                                        <span class="priceCons">
                                            {{
                                            (this.selectedService.consultationPrice).toLocaleString('en-in')
                                            }} ₹
                                        </span>
                                        <span class="durationConst">
                                            {{
                                            this.selectedService.consultationHours}} Hour
                                        </span>
                                    </p>
                                    <p>
                                        Addressing client problems
                                        by offering Video Call Session and helping you with :
                                    </p>
                                    <mat-list class="single-list-wrapping">
                                        <mat-list-item lines="3">
                                            <mat-icon class="singleItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle>
                                                Requirement planning </span>
                                        </mat-list-item>
                                        <mat-list-item lines="3">
                                            <mat-icon class="singleItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> Strategic
                                                planning </span>
                                        </mat-list-item>
                                        <mat-list-item lines="3">
                                            <mat-icon class="singleItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle>Q&A session
                                                for personalized solutions </span>
                                        </mat-list-item>
                                        <mat-list-item lines="3">
                                            <mat-icon class="singleItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> Contacts
                                                and Network to access valuable resources
                                                and people.
                                            </span>
                                        </mat-list-item>
                                        <mat-list-item lines="3">
                                            <mat-icon class="singleItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> Clear
                                                guidelines on Do's & Don'ts - Advantages &
                                                Distadvantages. </span>
                                        </mat-list-item>
                                    </mat-list>
                                    <p>
                                        Our focused and
                                        efficient approach ensures that you receive immediate, tailored
                                        assistance for their specific challenges.
                                    </p>

                                </div>
                            </mat-tab>
                            <mat-tab label="Project">
                                <div class="packageCons consType">
                                    <p class="highlight">
                                        <span class="priceCons">
                                            {{
                                            (this.selectedService.projectPrice).toLocaleString('en-in')
                                            }} ₹
                                        </span>
                                        <span class="durationConst">
                                            {{this.selectedService.projectHours }} Hours
                                        </span>
                                    </p>
                                    <p>
                                        Offering Video Call Sessions providing you with comprehensive insights into
                                        project stages through:
                                    </p>
                                    <mat-list class="project-list-wrapping">
                                        <mat-list-item lines="3">
                                            <mat-icon class="projectItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> In-depth
                                                analysis </span>
                                        </mat-list-item>

                                        <mat-list-item lines="3">
                                            <mat-icon class="projectItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> Strategic
                                                planning </span>
                                        </mat-list-item>

                                        <mat-list-item lines="3">
                                            <mat-icon class="projectItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> Risk
                                                assessment </span>
                                        </mat-list-item>

                                        <mat-list-item lines="3">
                                            <mat-icon class="projectItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> Milestone
                                                identification </span>
                                        </mat-list-item>

                                        <mat-list-item lines="3">
                                            <mat-icon class="projectItemTick">check_circle_outline</mat-icon> <span
                                                matListItemTitle> Budgeting
                                                insights</span>
                                        </mat-list-item>

                                    </mat-list>
                                    <p>
                                        We specialize in complex projects, offering tailored recommendations and a
                                        guarantee
                                        of successful outcomes through our expert guidance.
                                    </p>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div *ngIf="!this.isPaid" class="selectOne">
                        <div class="freeCons consType">
                            <p class="highlight">
                                <span class="priceCons">
                                    Free
                                </span>
                                <span class="durationConst">
                                    15 minutes
                                </span>
                            </p>
                            <p>Expert guidance, helps you by addressing major and immediate problems.
                                This brief and valuable interaction aims to
                                offer :</p>
                            <mat-list class="free-list-wrapping">
                                <mat-list-item lines="3">
                                    <mat-icon class="freeItemTick">check_circle_outline</mat-icon> <span
                                        matListItemTitle> 15 minutes of free
                                        audio call
                                        session </span>
                                </mat-list-item>
                                <mat-list-item lines="3">
                                    <mat-icon class="freeItemTick">check_circle_outline</mat-icon> <span
                                        class="tickItem"></span> <span matListItemTitle> Immediate insights and
                                        assistance </span>
                                </mat-list-item>
                                <mat-list-item lines="3">
                                    <mat-icon class="freeItemTick">check_circle_outline</mat-icon> <span
                                        class="tickItem"></span> <span matListItemTitle> Maximum 2 Free consultations
                                        allowed </span>
                                </mat-list-item>
                            </mat-list>
                            <p>Helping you make informed decisions
                                regarding their concerns at no cost.</p>
                        </div>
                    </div>
                </div>
            </mat-card>

            <div class="head">
                <span class="title">Fill Contact Form</span>
            </div>
            <mat-card>
                <form [formGroup]="consultBookingForm" (submit)="onDone()">
                    <div class="formFields">
                        <div class="field">
                            <mat-form-field appearance="outline">
                                <input matInput type="text" name="firstname" formControlName="firstname"
                                    placeholder="First Name" required>
                                <mat-error *ngIf="formErrors.firstname">{{formErrors.firstname}}</mat-error>
                                <mat-hint>Jack</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field appearance="outline">
                                <input matInput type="text" name="lastname" formControlName="lastname"
                                    placeholder="Last Name">
                                <mat-error *ngIf="formErrors.lastname">{{formErrors.lastname}}</mat-error>
                                <mat-hint>Smith</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field appearance="outline">
                                <input matInput type="text" name="companyName" formControlName="companyName"
                                    placeholder="Company Name (Optional)">
                                <mat-error *ngIf="formErrors.lastname">{{formErrors.companyName}}</mat-error>
                                <mat-hint>ABC pvt. ltd.</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field appearance="outline">
                                <input matInput name="email" formControlName="email" placeholder="Email" required>
                                <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                                <mat-hint>Johnsmith1@emailprovider.com</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field appearance="outline">
                                <input matInput type="tel" name="phone" formControlName="phone"
                                    placeholder="Phone Number" pattern="[0-9]*" maxlength="10" required>
                                <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                                <mat-hint>98xxxxxxx9</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field>
                                <mat-label>PIN Code</mat-label>
                                <input [(ngModel)]="selectedPincode" (ngModelChange)="getPincodeDetails()" matInput
                                    formControlName="pinCode" autocomplete="off" maxlength="6" pattern="[0-9]*" />
                                <mat-hint>226020</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field>
                                <input matInput type="text" [(ngModel)]="stateName" name="state" formControlName="state"
                                    placeholder="State">
                                <mat-error *ngIf="formErrors.state">{{formErrors.state}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field>
                                <input matInput type="text" name="city" [(ngModel)]="districtName"
                                    formControlName="city" placeholder="City">
                                <mat-error *ngIf="formErrors.city">{{formErrors.city}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <mat-form-field>
                                <mat-label>Locality</mat-label>
                                <input matInput placeholder="Search" [(ngModel)]="localityName" [matAutocomplete]="auto"
                                    formControlName="addressLocality" />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <ng-container>
                                        <mat-option *ngFor="let locality of this.pinLocalities"
                                            [value]="locality || null">
                                            <span>{{locality}}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="field buttonField">
                            <button class="submit-button" mat-raised-button color="accent" type="submit">Done
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>