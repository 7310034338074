import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PostsAndFaqFullInfoModel } from '../../datamodels/PostsFaqs/faqAndPostsModel';
import { SEOairService } from 'src/app/services/seoair.service';

@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.css']
})
export class ViewPostComponent implements OnInit {
  useFaq: PostsAndFaqFullInfoModel[] = []
  useId: number;
  useMetaInfo: string;
  usePost: PostsAndFaqFullInfoModel
  constructor(private route: ActivatedRoute, private title: Title, private meta: Meta
    , private airSEO: SEOairService) { }

  ngOnInit(): void {
    this.route.params.subscribe(getParam => {
      this.useId = getParam.id
      this.useMetaInfo = getParam.metaInfo
      this.findAnswSetMeta(this.useId, this.useMetaInfo)
    })
  }
  findAnswSetMeta(useId: number, metaTag: string) {
    this.useFaq = this.airSEO.getFAQsOnly()
    this.usePost = this.useFaq.find(eachFaq => { return eachFaq.id == useId });
    this.title.setTitle('Posts')
  }
}
