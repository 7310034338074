<div class="cards">
    <div class="cardsLayout">
        <div (click)="openLink()" class="card c0">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <a class="actionText" target="_blank" href="https://www.myapaar.com"> Bulk Selling <br> & Buying</a>
            </div>
        </div>
        <div (click)="this.fillServiceDetails('precisionAgriculture')" class="card c1">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Precision Agriculture</span>
            </div>
        </div>
        <div (click)="this.fillServiceDetails('hydroponicFarming')" class="card c2">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Hydroponic Farming</span>
            </div>
        </div>
        <div (click)="this.fillServiceDetails('soilTestingAnalysis')" class="card c3">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Soil Testing <br> & Analysis </span>
            </div>
        </div>
        <div (click)="this.fillServiceDetails('cropRotationPlanning')" class="card c4">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Crop Planning <br> & Rotation</span>
            </div>
        </div>
        <div (click)="this.fillServiceDetails('waterManagementIrrigation')" class="card c5">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Water Management <br> & Irrigation</span>
            </div>
        </div>
        <div (click)="this.fillServiceDetails('agricultureTrainingSessions')" class="card c6">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Agriculture Training <br> Sessions</span>
            </div>
        </div>

        <div (click)="this.fillServiceDetails('cropGeneticsBreeding')" class="card c7">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Crop Genetics <br> & Breeding</span>
            </div>
        </div>

        <div (click)="this.fillServiceDetails('diseasePestControl')" class="card c8">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Crop Disease & <br>Pest Control</span>
            </div>
        </div>

        <div (click)="this.fillServiceDetails('scientistSiteVisit')" class="card c9">
            <div class="imageTop">
            </div>
            <div class="actionBottom">
                <span class="actionText">Scientist Site Visit</span>
            </div>
        </div>
    </div>
</div>