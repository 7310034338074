import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { newroutes } from './routes'
import { AuthGuardV2CS } from 'src/app/auth-v2-cs/auth.guard-v2-cs';

@NgModule({
  imports: [RouterModule.forRoot(newroutes)],
  exports: [RouterModule],
  providers: [AuthGuardV2CS]
})
export class AppRoutingModule { }
