import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthV2CsServiceService } from 'src/app/auth-v2-cs/auth-v2-cs-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;
  loginform: FormGroup;
  hide = true;
  private authListenerSubs: Subscription;
  validationMessages = {
    'email': {
      'required': 'Email is required',
      'email': 'Email not in valid format'
    },
    'password': {
      'required': 'Password is required'
    },
    'masterKey': {
      'required': 'Password is required'
    }
  }
  formErrors = {
    'email': '',
    'password': '',
    'masterKey': '',
  }
  constructor(
    public authV2CSService: AuthV2CsServiceService

  ) { }

  ngOnInit(): void {
    this.authListenerSubs = this.authV2CSService.getairAuthStatusListener().subscribe(
      //we get auth status which is a boolean
      authStatus => {
        this.isLoading = false;
      }
    )
    this.loginform = new FormGroup({
      email: new FormControl('', { validators: [Validators.required, Validators.email] }),
      password: new FormControl('', { validators: [Validators.required] }),
      masterKey: new FormControl('')
    });
    this.loginform.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }
  ngOnDestroy() {
    //prevent any data leak
    this.authListenerSubs.unsubscribe();
  }
  onValueChanged(data?: any) {
    if (!this.loginform) {
      return;
    }
    const lform = this.loginform
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        //clear previous messages if any
        this.formErrors[field] = '';
        const control = lform.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + '';
            }
          }
        }
      }
    }
  }
  onLogin() {
    if (this.loginform.invalid) {
      return;
    }
    this.isLoading = true; //spinner starts
    {
      this.authV2CSService.loginAirUser(
        this.loginform.value.email,
        this.loginform.value.password,
        this.loginform.value.masterKey
      )
    }


  }
}
