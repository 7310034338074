<mat-spinner *ngIf="isLoading"></mat-spinner>


<div class="loginForm" *ngIf="!isLoading">
    <mat-card>
        <form [formGroup]="loginform" (submit)="onLogin()" *ngIf="!isLoading">
            <mat-form-field appearance="outline">
                <input matInput type="email" name="email" formControlName="email" placeholder="Email" required>
                <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                <mat-hint>Johnsmith1@emailprovider.com</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput type="password" name="password" formControlName="password" placeholder="Password"
                    [type]="hide? 'password':'text'" required>
                <button mat-icon-button matSuffix (click)="hide=!hide" type="button" [attr.aria-label]="'Hide Password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide? 'visibility_off' : 'visibility'}}</mat-icon></button>
                <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
            </mat-form-field>
            <br><br>
            <button mat-raised-button color="accent" type="submit">Login
            </button>
        </form>
    </mat-card>
</div>