import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthV2CsServiceService } from 'src/app/auth-v2-cs/auth-v2-cs-service.service';
import { ConsultationBooking } from 'src/app/datamodels/newConsultationModel';
import { UserDetails } from 'src/app/datamodels/userClientModel';
import { SavedConsultationsUser } from 'src/app/datamodels/userConsultationBookings';
import { BookServicesService } from 'src/app/services/book-services.service';

@Component({
  selector: 'app-booking-page',
  templateUrl: './booking-page.component.html',
  styleUrls: ['./booking-page.component.css']
})
export class BookingPageComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;
  currentServiceCode: string;
  currentServiceName: string;
  currentSpecialService: string;
  currentBannerService: string;
  selectedPincode: string = '';
  consultBookingForm: FormGroup
  stateName: string;
  districtName: string;
  pincodeFullInfo: any = [];
  pinLocalities
  localityName: string;
  selectedTab: number = 0
  selectedConsultationType: ConsultationBooking;
  isPaid: boolean = true;
  loginUserInfo: any;
  currentUserId: string;
  constructor(private route: ActivatedRoute,
    private bookServices: BookServicesService,
    private _snackBar: MatSnackBar,
    public authV2CSService: AuthV2CsServiceService,
    private router: Router) { }
  allServices: ConsultationBooking[] = [
    {
      id: 1,
      consultationPrice: 1300,
      consultationHours: 1,
      projectPrice: 26000,
      projectHours: 26,
      serviceName: 'Precision Agriculture',
      serviceCode: 'precisionAgriculture',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/precisionAgriculture.jpg'
    },
    {
      id: 2,
      consultationPrice: 900,
      consultationHours: 1,
      projectPrice: 21000,
      projectHours: 26,
      serviceName: 'Hydroponic Farming',
      serviceCode: 'hydroponicFarming',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/hydroponicfarming.jpg'
    },
    {
      id: 3,
      consultationPrice: 700,
      consultationHours: 1,
      projectPrice: 15000,
      projectHours: 26,
      serviceName: 'Soil Testing & Analysis',
      serviceCode: 'soilTestingAnalysis',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/Soilplanning.jpg'
    },
    {
      id: 4,
      consultationPrice: 700,
      consultationHours: 1,
      projectPrice: 15000,
      projectHours: 26,
      serviceName: 'Crop Planning & Rotation',
      serviceCode: 'cropRotationPlanning',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/cropPlanningRotaion.jpg'
    },
    {
      id: 5,
      consultationPrice: 500,
      consultationHours: 1,
      projectPrice: 13000,
      projectHours: 26,
      serviceName: 'Water Management & Irrigation',
      serviceCode: 'waterManagementIrrigation',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/irrigation.jpg'
    },
    {
      id: 6,
      consultationPrice: 600,
      consultationHours: 1,
      projectPrice: 13000,
      projectHours: 26,
      serviceName: 'Agriculture Training Sessions',
      serviceCode: 'agricultureTrainingSessions',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/agricultureTraining.jpg'
    },
    {
      id: 7,
      consultationPrice: 1100,
      consultationHours: 1,
      projectPrice: 26000,
      projectHours: 26,
      serviceName: 'Crop Genetics & Breeding',
      serviceCode: 'cropGeneticsBreeding',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/cropbreedingTesting.jpg'
    },
    {
      id: 8,
      consultationPrice: 900,
      consultationHours: 1,
      projectPrice: 21000,
      projectHours: 26,
      serviceName: 'Crop Disease & Pest Control',
      serviceCode: 'diseasePestControl',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/cropDisease.jpg'
    },
    {
      id: 9,
      consultationPrice: 2600,
      consultationHours: 1,
      projectPrice: 65000,
      projectHours: 26,
      serviceName: 'Scientist Site Visit',
      serviceCode: 'scientistSiteVisit',
      serviceOptions: [],
      isFree: false,
      isSingle: false,
      isProject: false,
      bannerImage: '../../../assets/servicesBanner/scientistFarmVisit.jpg'
    }
  ]
  validationMessages = {
    'firstname': {
      'required': 'First name is required',
      'maxlength': 'First name cannot have more than 50 characters!'
    },
    'lastname': {
      'required': 'Last name is required',
      'maxlength': 'Last name cannot have more than 50 characters!'
    },
    'companyName': {
      'required': 'Last name is required',
      'maxlength': 'Last name cannot have more than 50 characters!'
    },
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
    'email': {
      'required': 'Email is required',
      'email': 'Email not in valid format'
    },
    'pinCode': {
      'required': 'Pincode is required',
      'pinCode': 'Only numbers allowed'
    },
    'city': {
      'required': 'City is required',
    },
    'state': {
      'required': 'state is required',
    },
  }
  //js object to display form errors
  formErrors = {
    'firstname': '',
    'lastname': '',
    'companyName': '',
    'phone': '',
    'email': '',
    'pinCode': '',
    'city': '',
    'state': '',
    'addressLocality': '',
  }
  selectedService: ConsultationBooking
  ngOnInit(): void {
    try {
      this.route.paramMap.subscribe((currentParam: Params) => {
        if (!currentParam || currentParam === undefined) {
          this.router.navigate(['/'])
        }
        this.currentServiceCode = currentParam.params.consultationType
        this.selectedService = this.allServices.find(eachService => {
          return eachService.serviceCode === this.currentServiceCode
        })
        this.currentServiceName = this.selectedService.serviceName
        this.currentBannerService = this.selectedService.bannerImage
        this.consultBookingForm = new FormGroup({
          firstname: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
          lastname: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
          companyName: new FormControl(''),
          phone: new FormControl('', {
            validators: [Validators.minLength(10), Validators.maxLength(10)]
          }),
          email: new FormControl('', {
            validators: [Validators.maxLength(250), Validators.minLength(5), Validators.pattern(/.+@.+\..+/)]
          }),
          addressLocality: new FormControl(''),
          pinCode: new FormControl(null, {
            validators: [Validators.maxLength(6)]
          }),
          city: new FormControl('', { validators: [Validators.required, Validators.maxLength(30)] }),
          state: new FormControl('', { validators: [Validators.required, Validators.maxLength(30)] }),
        });
        this.consultBookingForm.valueChanges.subscribe(data => {
          this.onValueChanged(data)
        });
        this.onValueChanged();
      })
    }
    catch (e) {
      throw e
    }
  }

  ngAfterViewInit() {
    try {
      this.getUserDetailsAndAddToForm()
      this.selectedTab = this.tabGroup.selectedIndex
    }
    catch (e) {
      throw e
    }

  }
  onValueChanged(data?: any) {
    try {
      if (!this.consultBookingForm) {
        return;
      }
      const sform = this.consultBookingForm;
      for (const field in this.formErrors) {
        if (this.formErrors.hasOwnProperty(field)) {
          //clear previous messages if any
          this.formErrors[field] = '';
          const control = sform.get(field);
          if (control && control.dirty && !control.valid) {
            const messages = this.validationMessages[field];
            for (const key in control.errors) {
              if (control.errors.hasOwnProperty(key)) {
                this.formErrors[field] += messages[key] + '';
              }
            }
          }
        }
      }
    }
    catch (e) {
      throw e
    }
  }
  getUserDetailsAndAddToForm() {
    this.loginUserInfo = this.authV2CSService.getAirLoginInfo()
    this.currentUserId = this.loginUserInfo.userId
    if (this.loginUserInfo && this.loginUserInfo !== undefined) {
      this.consultBookingForm.patchValue({
        'firstname': this.loginUserInfo.firstname,
        'lastname': this.loginUserInfo.lastname,
        'email': this.loginUserInfo.email,
        'phone': this.loginUserInfo.phone,
      })
    }
  }
  async getPincodeDetails() {
    try {
      if (this.selectedPincode.length == 6) {
        const pincodeInfo = await this.bookServices.getPincodeDetails(this.selectedPincode)
        this.pincodeFullInfo = pincodeInfo.pinCodeInfo
        this.stateName = this.pincodeFullInfo[0].State
        this.districtName = this.pincodeFullInfo[0].District
        const localities = [...new Set(this.pincodeFullInfo.map((obj: any) => obj.Name))];
        this.pinLocalities = localities
        this.localityName = ""
      }
    }
    catch (e) {
      throw e
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    try {
      this.selectedTab = tabChangeEvent.index
    }
    catch (e) {
      throw e
    }
  }

  onDone() {
    try {
      let isPaymentRequired = true
      if (this.consultBookingForm.invalid) {
        return
      }
      if (this.consultBookingForm.valid) {
        if (!this.isPaid) {
          this.selectedTab = null
          isPaymentRequired = false
          this.selectedConsultationType = {
            id: this.selectedService.id,
            serviceName: this.selectedService.serviceName,
            serviceCode: this.selectedService.serviceCode,
            serviceOptions: this.selectedService.serviceOptions,
            isFree: true,
            isSingle: false,
            isProject: false,
            consultationPrice: this.selectedService.consultationPrice,
            consultationHours: this.selectedService.consultationHours,
            projectPrice: this.selectedService.projectPrice,
            projectHours: this.selectedService.projectHours,
            bannerImage: this.selectedService.bannerImage
          }
        }
        if (this.isPaid && this.selectedTab === 0 || 1) {
          if (this.selectedTab === 0) {
            this.selectedConsultationType = {
              id: this.selectedService.id,
              serviceName: this.selectedService.serviceName,
              serviceCode: this.selectedService.serviceCode,
              serviceOptions: this.selectedService.serviceOptions,
              isFree: false,
              isSingle: true,
              isProject: false,
              consultationPrice: this.selectedService.consultationPrice,
              consultationHours: this.selectedService.consultationHours,
              projectPrice: this.selectedService.projectPrice,
              projectHours: this.selectedService.projectHours,
              bannerImage: this.selectedService.bannerImage
            }
          }
          if (this.selectedTab === 1) {
            this.selectedConsultationType = {
              id: this.selectedService.id,
              serviceName: this.selectedService.serviceName,
              serviceCode: this.selectedService.serviceCode,
              serviceOptions: this.selectedService.serviceOptions,
              isFree: false,
              isSingle: false,
              isProject: true,
              consultationPrice: this.selectedService.consultationPrice,
              consultationHours: this.selectedService.consultationHours,
              projectPrice: this.selectedService.projectPrice,
              projectHours: this.selectedService.projectHours,
              bannerImage: this.selectedService.bannerImage
            }
          }
        }
        const sendConsultation: SavedConsultationsUser = {
          userDetails: {
            firstname: this.consultBookingForm.value.firstname,
            lastname: this.consultBookingForm.value.lastname,
            companyName: this.consultBookingForm.value.companyName,
            contactDetails: {
              phone: this.consultBookingForm.value.phone,
              email: this.consultBookingForm.value.email,
              pinCode: this.consultBookingForm.value.pinCode,
              addressLocality: this.consultBookingForm.value.addressLocality,
              city: this.consultBookingForm.value.city,
              state: this.consultBookingForm.value.state,
            }
          },
          consultationDetails: this.selectedConsultationType,
          paymentRequired: isPaymentRequired,
          paymentDone: false
        }
        if (sendConsultation.consultationDetails.isFree) {
          this.bookDirectly(sendConsultation)
        }
        if (!sendConsultation.consultationDetails.isFree) {
          this.saveBookingDetailsGoToPaymentPage(sendConsultation)
        }
      }
    }
    catch (e) {
      throw e
    }
  }

  bookDirectly(savefreeConsultation: SavedConsultationsUser) {
    this.bookServices.saveConsultationBooking(savefreeConsultation, this.currentUserId).then(savedCons => {
      const bookingId = savedCons.bookingId
      this.itemCreateSuccessful("Booking Created", 'Ok', bookingId)
    })
  }

  saveBookingDetailsGoToPaymentPage(savePaidConsultationThenPay: SavedConsultationsUser) {
    this.bookServices.saveConsultationBooking(savePaidConsultationThenPay, this.currentUserId).then(savedCons => {
      const bookingId = savedCons.bookingId
      this.itemCreateSuccessful("Booking Created", 'Ok', bookingId)
    })
  }

  itemCreateSuccessful(message: string, action: string, bookingId: string) {
    let snackBarRef = this._snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
    snackBarRef.onAction().subscribe(() =>
      this.router.navigate(['/MyBookings'])
    )
    snackBarRef.afterDismissed().subscribe(() =>
      this.router.navigate(['/MyBookings'])
    )
  }
}
