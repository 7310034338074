<div class="contentAbout">
    <div class="serviceCard part1">
        <div class="serviceBanner1">
            <div class="centered">
                <h1 class="heading"> All Indus Roots</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/allindusrootsmain.jpg"
                alt="All Indus Roots about platform" style="width:100%;">
        </div>
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">
                    Leading in Agri Consultation
                </h1>
                <h3 class="subHead">
                    Growing With India
                </h3>
            </div>
            <div class="mainContent">
                <p>
                    Your trusted partner in agricultural growth and sustainability. At All Indus Roots, we offer
                    comprehensive agri consultation services tailored to the <span class="highlight">needs of
                        companies,
                        farmers, crop managers, and agriculture startups.</span>
                </p>
                <p>
                    Our platform is designed to provide<span class="highlight">
                        invaluable insights, expert
                        advice, and hands-on experience
                    </span> to
                    optimize crop management and foster agricultural success.
                </p>
                <p>
                    <span class="highlight"> India is an agriculture economy, and we are here to help you Grow Like a
                        Pro </span>
                </p>
            </div>
        </div>
    </div>
    <div class="serviceCard part2">
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">
                    Better Yield Healthy Harvest
                </h1>
                <h3 class="subHead">
                    Each Garden Each Farm
                </h3>
            </div>
            <div class="mainContent">
                <p>
                    Our mission is to empower farmers and agricultural enterprises with the knowledge and tools they
                    need to thrive, that fosters growth and prosperity for <span class="highlight">farmers and
                        businesses
                        of all
                        sizes.</span>
                </p>
                <p>
                    We are committed to driving innovation, sustainability, and profitability in the agriculture sector
                    by delivering top-notch consultation services and support.
                </p>
            </div>
        </div>
        <div class="serviceBanner2">
            <div class="centered">
                <h1 class="heading">Mission</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/cocoa-harvest.jpg"
                alt="All Indus Roots about seller" style="width:100%;">
        </div>
    </div>
    <div class="serviceCard part3">
        <div class="serviceBanner3">
            <div class="centered">
                <h1 class="heading">Vision</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/yield.jpg" alt="All Indus Roots about buyer"
                style="width:100%;">
        </div>
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">
                    Half knowledge is dangerous for your Crops
                </h1>
                <h3 class="subHead">
                    Subject Matter Experts
                </h3>
            </div>
            <div class="mainContent">
                <p>
                    We envision a future where all farmers and all businesses can access
                    a vast
                    network of opportunities, driving growth, prosperity, and sustainable commerce on a global scale.
                </p>
                <p>
                    Indian Products have a significant popularity
                    globally due to
                    their quality, uniqueness, and
                    affordability.
                    We aspire to redefine the way agricultural growth and crop management is handled, and
                    become <span class="highlight"> India's most
                        loved
                        Agri Consultation platform</span> where efficiency,
                    trust, and
                    success join hands.
                </p>
                <p>
                    It's well known <span class="highlight"> While ignorance is bliss, half knowledge – as it is said –
                        can be dangerous</span>. Book your Agri Consultation to gain full knowledge from subject matter
                    experts.
                </p>
            </div>
        </div>
    </div>
    <div class="serviceCard part4">
        <div class="contentAboutusteam2">
            <div class="servicesList">
                <div class="serv1 secServ">
                    <div class="imgSec im1">
                    </div>
                    <div class="descSec">
                        <p class="desc de1">
                            Tailored Consultation Services
                        </p>
                    </div>
                </div>
                <div class="serv2 secServ">
                    <div class="imgSec im2">
                    </div>
                    <div class="descSec">
                        <p class="desc de2">
                            Comprehensive Packages
                        </p>
                    </div>
                </div>
                <!-- <div class="serv3 secServ">
                    <div class="imgSec im3">
                    </div>
                    <div class="descSec">
                        <p class="desc de3">
                            Expert Insights and Advice
                        </p>
                    </div>
                </div> -->
                <div class="serv4 secServ">
                    <div class="imgSec im4">
                    </div>
                    <div class="descSec">
                        <p class="desc de4">
                            Grow your Brand Better
                        </p>
                    </div>
                </div>
                <div class="serv5 secServ">
                    <div class="imgSec im5">
                    </div>
                    <div class="descSec">
                        <p class="desc de5">
                            Affordability and Return On Investment
                        </p>
                    </div>
                </div>
                <div class="serv6 secServ">
                    <div class="imgSec im6">
                    </div>
                    <div class="descSec">
                        <p class="desc de6">
                            Growth and Better Yield
                        </p>
                    </div>
                </div>
                <div class="serv7 secServ">
                    <div class="imgSec im7">
                    </div>
                    <div class="descSec">
                        <p class="desc de7">
                            Customer Support Solutions
                        </p>
                    </div>
                </div>
            </div>
            <div class="finalClosing">
                <h1>
                    Join us at All Indus Roots and Unlock the full potential of your crops and agricultural endeavors.
                </h1>
                <h3>
                    We're here to support you every step of the way.
                </h3>
                <h5>
                    Free Registration, Free Consultation.
                </h5>
            </div>
        </div>
    </div>
</div>